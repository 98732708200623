.page-contact-us {
  background-color: #F8F8F8;
  font-family: 'Poppins', sans-serif;
  position: relative;
  top: 100px;
  z-index: -2;

  .section-contact-us {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -70px;
    z-index: 1;

    .contact-us-inner {
      display: flex;
      justify-content: space-between;
      left: 48px;
      max-width: 1066px;
      margin: auto;
      padding: 103px 0 10px 0;
      position: relative;
    }

    .background-shape {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      height: 249px;
      position: relative;
      top: 70px;
      width: 140px;

      .background-shape-inner {
        background-color: #F8F8F8;
        border-radius: 0 96px 0 0;
        height: 249px;
        width: 140px;
        z-index: 1;
      }
    }
  }

  .section {
    margin-bottom: 30px;

    .details {
      align-items: center;
      display: flex;
      margin-bottom: 15px;

      .icon {
        margin: 0 15px 0 0;
      }

      .value {
        color: #052850;
        font-weight: 500;
        width: 280px;
      }
    }

    .description {
      color: #052850;
      margin-right: 20px;
      width: 237px;
    }

    .img-map {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 224px;
      width: 456px;
    }

    .logo-wrap {
      margin-bottom: 32px;
    }

    .title {
      color: #052850;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 40px;

      .divider {
        border-bottom: 2px solid #E0AA32;
        padding-top: 10px;
        width: 39px;
      }
    }
  }

  .sub-title {
    color: #E0AA32;
    font-size: 13px;
    font-weight: 600;
    max-width: 1066px;
    margin: auto;

    &.bold-text {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .top {
    background-color: white;
    border-radius: 0 0 0 60px;
    padding: 120px 150px 20px 150px;
  }

  .title {
    color: #062951;
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 20px;
    max-width: 1066px;
    margin: auto;

    .text-yellow {
      color: #E0AA32;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 760px) {
  .page-contact-us {
    .section-contact-us {
      .background-shape {
        display: none;
      }

      .contact-us-inner {
        flex-direction: column;
        left: 0;
        padding: 100px 25px;
        width: 100%;
      }
    }

    .top {
      padding: 25px;

      .title {
        font-size: 30px;
      }
    }
  }
}