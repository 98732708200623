.student-stories-wrap {

    .story-wrap {
        text-align: center;

        .description {
            color: white;
            font-size: 13px;
        }

        .student-img {
            background-size: cover;
            border-radius: 16px;
            height: 82px;
            margin: auto auto 20px auto;
            opacity: 0.5;
            width: 82px;
        }
    
        .student-name {
            color: white;
            font-size: 18px;
            font-weight: 800;
        }
    
        .university-name {
            color: white;
            font-size: 11px;
            font-weight: 500;
            margin-bottom: 15px;
            text-transform: uppercase;
            opacity: 0.6;
        }
    }


    .slick-slide {
        display: flex;
        align-items: center;
        justify-self: center;
       // width: 100px !important;

     &.slick-active {
        .student-img {
            width: 150px !important;
            height: 150px !important;
            opacity: 1;
        }
         }
    }

    .ant-carousel {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: auto;
    }
    .slick-slider {
     //   width: 820px !important;
        display: flex !important;
        margin-bottom: 20px;
    }
}