.page-about-us {
  background-color: #f9f6ef;
  font-family: "Poppins", sans-serif;
  // position: relative;
  top: 100px;
  z-index: -3;

  .about-us-inner {
    background-color: white;
    border-radius: 0 0 0 60px;
    padding: 210px 0 196px 0;
    position: relative;
    width: 100%;
    z-index: 2;

    .text-content {
      display: flex;
      justify-content: space-between;
      max-width: 1066px;
      margin: auto;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    .background-shape {
      background: #ffffff 0% 0% no-repeat padding-box;
      height: 249px;
      position: relative;
      width: 140px;

      .background-shape-inner {
        background-color: #f9f6ef;
        border-radius: 0 96px 0 0;
        height: 249px;
        width: 140px;
        z-index: 1;
      }
    }

    .column {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      column-gap: 42px;
    }

    .our-team {
      background-color: #f9f6ef;
      position: relative;
      padding: 140px 10px 0 150px;
      top: -60px;
      text-align: center;
      width: calc(100vw - 140px);
      z-index: 1;

      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        padding-bottom: 80px;
        width: 685px;
        column-gap: 42px;

        .team-member {
          margin-bottom: 40px;
          width: 200px;

          .image {
            img {
              border-radius: 20px;
            }
          }

          .name {
            font-size: 15px;
            font-weight: 600;
          }

          .position {
            font-size: 13px;
          }
        }
      }

      .text {
        font-size: 13px;
        margin-bottom: 60px;

        .bold {
          font-weight: 600;
        }
      }
      .text-ceo {
        font-size: 16px;
        .bold {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  .section {
    margin-bottom: 30px;

    .description {
      color: #052850;
      margin-right: 20px;
      width: 415px;
    }

    .second-block {
      margin: 20px 0 50px 0;
      width: 415px;
    }

    .img-map {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 278px;
      margin-top: 60px;
      width: 564px;
    }
  }

  .sub-title {
    color: #e0aa32;
    font-size: 13px;
    font-weight: 600;

    &.bold-text {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .title {
    color: #062951;
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 20px;

    .text-yellow {
      color: #e0aa32;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 760px) {
  .page-about-us {
    .about-us-inner {
      flex-direction: column;
      padding: 130px 25px 25px 25px;

      .img-map {
        height: 159px;
        width: 324px;
      }

      .section {
        .description {
          width: auto;
        }

        .second-block {
          width: auto;
        }
      }

      .text-content {
        flex-direction: column;
      }

      .title {
        font-size: 30px;
      }
    }

    .content {
      .background-shape {
        display: none;
      }

      .our-team {
        padding: 100px 25px 25px 25px;
        width: 100%;

        .content {
          width: auto;

          .team-member {
            width: 150px;
          }
        }

        .title {
          font-size: 30px;
        }
      }
    }
  }
}
