.header-top {
  background-color: #07264A;
  color: white;
  height: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .header-inner {
    align-items: center;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    justify-content: space-between;
    // margin: 0 150px 0 150px;
    margin: auto;
    max-width: 1066px;
    padding: 8px 0;
    width: 82%;

    .icon {
      margin: 0 4px 0 15px;
    }
  }

  .left {
    display: flex;

    .item {
      color: #FFFFFF;
      margin-right: 20px;
      padding-bottom: 4px;
    }
  }

  .right {
    display: flex;

    .item {
      color: #FFFFFF;
      margin-right: 20px;

      &.active {
        border-bottom: 2px solid #E0AA32;
        color: #E0AA32;
        font-weight: 700;
        padding-bottom: 3px;
      }
    }

    .icon {
      cursor: pointer;
    }
  }
}

.header {
  background-color: #062951;
  box-shadow: 0px 1px 10px #00000029;
  color: white;
  height: 70px;
  position: fixed;
  top: 30px;
  width: 100%;
  z-index: 16;

  .header-inner {
    align-items: center;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    justify-content: space-between;
    margin: auto;
    max-width: 1066px;
    padding: 15px 0;
    width: 82%;
    //margin: 0 150px 0 150px;

    .item {
      color: white;
      font-size: 13px;

      .icon {
        margin-right: 5px;
      }
    }
  }

  .right {
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: 500;

    .btn-apply-now {
      background-color: #E0AA32;
      border-radius: 8px;
      color: black;
      font-size: 13px;
      font-weight: 600;
      margin-left: 20px;
      padding: 10px 22px;
    }

    .item {
      color: #FFFFFF;
    }
  }
}

.section-welcome {
  border-radius: 0 0 0 60px;
  background-color: white;
  position: relative;
  top: 100px;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 85px 0px 22px 0px;
    margin: auto;
    max-width: 1066px;
    width: 82%;

    .left {
      .how-it-works {
        color: #E0AA32;
        font-size: 13px;
        font-weight: 600;

        .list {
          color: #062951;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          margin: 10px 0 7px 0;

          .text-bold {
            font-weight: 600;
          }

          .number {
            background-color: #F7EACB;
            border-radius: 5px;
            color: #062951;
            font-weight: 500;
            height: 24px;
            margin-right: 10px;
            padding: 4px 6px;
            width: 24px;

            &.green {
              background-color: #E0FAE4;
            }

            &.purple {
              background-color: #ECE5FB;
            }

            &.yellow {
              background-color: #F6EDE8;
            }
          }
        }
      }

      .students {
        align-items: center;
        display: flex;
        margin-top: 40px;

        .students-inner {
          width: 320px;
        }

        .student-img {
          height: 51px;
          position: relative;
          width: 51px;

          &.stu1 {
            z-index: 6;
          }

          &.stu2 {
            left: -18px;
            z-index: 5;
          }

          &.stu3 {
            left: -35px;
            z-index: 4;
          }

          &.stu4 {
            left: -53px;
            z-index: 3;
          }

          &.stu5 {
            left: -71px;
            z-index: 2;
          }

          &.stu6 {
            left: -87px;
            z-index: 1;
          }
        }

        .link {
          color: #062951;
          cursor: pointer;
          font-size: 11px;
          font-weight: 500;
          position: relative;
          left: -76px;
          text-decoration: underline;
          width: 135px;
        }
      }

      .sub-title {
        color: #E0AA32;
        font-size: 13px;
        font-weight: 600;
      }

      .title {
        color: #062951;
        font-size: 40px;
        font-weight: 800;
        line-height: 45px;
        margin-bottom: 30px;
      }

    }

    .right {
      display: flex;
      margin-bottom: 38px;

      .banner-image {
        display: flex;
        z-index: 1;

        .dot-image {
          position: relative;
          top: -67px;
          left: 348px;
          width: 110px;
         // z-index: -2;
        }

        .yellow-background {
          background: #FAF2DF 0% 0% no-repeat padding-box;
          border: 3px solid #F8E6B9;
          border-radius: 50px;
          height: 338px;
          left: -45px;
          position: relative;
          top: 41px;
          width: 419px;
          // z-index: -1;

          .girl-image {
            display: flex;
            justify-content: center;
            margin: auto;
            position: relative;
            bottom: 210px;
          }
        }
      }

      .flag-wrap {
        display: flex;
        flex-direction: column;
        padding: 5px 50px;
        position: relative;
        left: 34px;
        z-index: 2;

        .flag {
          margin-bottom: 10px;
        }
      }
    }
  }

  .university-list {
    background-color: white;
    border-radius: 0 0 0 60px;
    padding: 40px 0;
    margin: auto;
    position: relative;
    z-index: 1;
   // max-width: 1207px;

   .uni-list-inner {
      max-width: 1207px;
      margin: auto;
   }

    .slick-track {
      display: flex;
      justify-content: space-between !important;
    }

    .slick-slide {
      width: 16% !important;
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      align-self: center;
      justify-content: center;
    }

    .slick-dots {
      display: none !important;
    }

    .university-img {
      max-height: 60px;
      max-width: 100px;
    }
  }
}

.whtsapp-logo-wrap {
  .whatsapp-logo {
    position: fixed;
    width: 63px;
    bottom: 30px;
    right: 50px;
  }
}

.whatsapp {
  .icon {
    bottom: 15px;
    position: fixed;
    right: 14%;
    z-index: 20;
  }
}

.section-video {
  background-color: #F9F6EF;
  // border-radius: 0 0 0 60px;
  display: flex;
  padding: 190px 0px 90px 90px;
  position: relative;

  .background-shape {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 249px;
    position: relative;
    top: -91px;
    width: 140px;

    .background-shape-inner {
      background-color: #F9F6EF;
      border-radius: 0 96px 0 0;
      height: 249px;
      width: 127px;
      z-index: 1;
    }
  }

  .video {
    display: flex;
    justify-content: center;
    width: calc(100vw - 99px);

    iframe {
      border-radius: 20px;
    }
  }
}

.section-study-abroad {
  background-color: #F8F8F8;
  border-radius: 0 0 0 60px;
  margin: auto;
  padding: 93px 0 170px 0;
  position: relative;
  text-align: center;
  // top: -120px;
  // z-index: -2;

  .btn-find-more {
    background-color: #E0AA32;
    border-radius: 8px;
    color: #052850;
    font-size: 13px;
    font-weight: 600;
    margin: auto;
    padding: 18px 24px;
    width: 150px;

    .arrow {
      margin-left: 10px;
    }
  }

  .background-shape {
    background-color: #F9F6EF;
    float: right;
    height: 249px;
    position: relative;
    top: -34px;
    width: 140px;

    .background-shape-inner {
      background-color: #F8F8F8;
      border-radius: 0 96px 0 0;
      height: 249px;
      width: 140px;
      z-index: 1;
    }
  }

  .country-icons {
    padding-left: 81px;

    .flag-1 {
      position: relative;
      z-index: 5;
    }

    .flag-2 {
      position: relative;
      left: -20px;
      z-index: 4;
    }

    .flag-3 {
      position: relative;
      left: -40px;
      z-index: 3;
    }

    .flag-4 {
      position: relative;
      left: -60px;
      z-index: 2;
    }

    .flag-5 {
      position: relative;
      left: -80px;
      z-index: 1;
    }
  }

  .country-banner-wrap {
    margin: auto auto 50px auto;
    text-align: center;
    max-width: 1066px;

    .country-banner-img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 40px;
      height: 384px;
      width: 1066px;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 20px;

    .text-yellow {
      color: #E0AA32;
    }
  }

  .text {
    font-size: 13px;
    margin: auto auto 30px auto;
    text-align: center;
    width: 680px;

    .text-bold {
      font-weight: 600;
    }
  }
}

.section-rates {
  background-color: #052850;
  border-radius: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 34px 100px;
  position: relative;
  top: -75px;
  max-width: 1066px;

  .item {
    margin: auto;
    text-align: center;
    // width: 218px;

    .definition {
      font-size: 13px;
    }

    .number {
      font-size: 40px;
      font-weight: 800;
    }
  }

  .line {
    border-right: 1px solid white;
    margin: 15px 0;
  }
}

.section-courses {
  position: relative;
  text-align: center;
  //top: -110px;

  .btn-find-more {
    background-color: #E0AA32;
    border-radius: 8px;
    color: #052850;
    font-size: 13px;
    font-weight: 600;
    margin: auto;
    padding: 18px 24px;
    width: 150px;

    .arrow {
      margin-left: 10px;
    }
  }

  // .background-shape {
  //   background-color: red;
  //   float: right;
  //   height: 249px;
  //   position: relative;
  //   top: -34px;
  //   width: 140px;

  //   .background-shape-inner {
  //     background-color: yellow;
  //     border-radius: 0 96px 0 0;
  //     height: 249px;
  //     width: 140px;
  //     z-index: 1;
  //   }
  // }

  .course-list {
    justify-content: center;
    display: flex;

    .item {
      border-radius: 20px;
      background-color: #FCF5D7;
      color: #062951;
      font-size: 13px;
      font-weight: 600;
      margin: 0 10px 30px 0;
      padding: 10px 15px;
    }
  }

  .courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
    max-width: 1066px;

    .img-wrap {
      border-radius: 20px;
      margin-bottom: 30px;
      padding: 10px;

      .course-name {
        font-size: 15px;
        font-weight: 800;
        padding: 24px 0 14px 0;
      }

      .image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 233px;
        width: 316px;
      }

      &.img1 {
        background-color: #E0FAE4;
      }

      &.img2 {
        background-color: #E4E4E4;
      }

      &.img3 {
        background-color: #ECE5FB;
      }

      &.img4 {
        background-color: #F6EDE8;
      }

      &.img5 {
        background-color: #E6F0FB;
      }

      &.img6 {
        background-color: #FCF5D7;
      }
    }
  }

  .sub-title {
    color: #E0AA32;
    font-size: 13px;
    font-weight: 600;
  }

  .title {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 20px;

    .text-yellow {
      color: #E0AA32;
    }
  }

  .text {
    color: #062951;
    font-size: 13px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.section-student-stories {
  background-color: #052850;
 // border-radius: 0 96px 0 96px;
  padding: 90px 0;
  margin-top: 110px;
  text-align: center;

  .student-stories-wrap {
    .slick-dots {
      &.slick-dots-bottom {
        bottom: -36px;
      }
    }
  }
  .sub-title {
    color: #E0AA32;
    font-size: 13px;
    font-weight: 600;
  }

  .title {
    color: white;
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 20px;

    .text-yellow {
      color: #E0AA32;
    }
  }
}

.section-about-us {
  .about-us-inner {
    display: flex;
    justify-content: space-between;
    padding: 90px 0px 50px 0px;
    margin: auto 220px auto auto;
    max-width: 1066px;
    width: 82%;
  }

  .background-shape {
    background-color: #052850;
    float: right;
    height: 249px;
    position: relative;
    width: 140px;

    .background-shape-inner {
      background-color: white;
      border-radius: 0 96px 0 0;
      height: 249px;
      width: 140px;
      z-index: 1;
    }
  }

  .section {
    margin-bottom: 30px;

    .details {
      align-items: center;
      display: flex;
      margin-bottom: 15px;

      .icon {
        margin: 0 15px 0 0;
      }

      .value {
        color: #052850;
        font-weight: 500;
        width: 280px;
      }
    }

    .description {
      color: #052850;
      margin-right: 20px;
      width: 237px;
    }

    .img-map {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 224px;
      width: 456px;
    }

    .logo-wrap {
      margin-bottom: 32px;
    }

    .title {
      color: #052850;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 40px;

      .divider {
        border-bottom: 2px solid #E0AA32;
        padding-top: 10px;
        width: 39px;
      }
    }
  }
}

.footer {
  background-color: #07264A;
  color: white;
  height: 30px;

  .footer-inner {
    align-items: center;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    justify-content: space-between;
    margin: auto;
    max-width: 1066px;
    padding: 8px 0;
    width: 82%;
  }

  .left {
    display: flex;

    .item {
      color: #FFFFFF;
      margin-right: 20px;
    }
  }

  .right {
    display: flex;

    .item {
      color: #FFFFFF;
      margin-right: 20px;
    }
  }
}


.menu-modal {
  max-width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;

  .ant-modal-header {
    background-color: #062951;
    color: white;
  }

  .ant-modal-content {
    height: 100vh;

    .ant-modal-body {
      padding-top: 60px;
      text-align: center;

      .btn {
        height: 50px;
        margin-bottom: 20px;
      }

      .btn-documents {
        background-color: #FAF2DF;
        border-radius: 8px;
        color: #062951;
        font-size: 13px;
        font-weight: 600;
        height: 50px;
        margin: 0 auto 20px auto;
        padding: 15px 60px;
        width: 250px;
      }

      .btn-apply {
        background-color: #E0AA32;
        border-radius: 8px;
        color: #062951;
        font-size: 13px;
        font-weight: 600;
        height: 50px;
        margin: auto;
        padding: 15px 87px;
        width: 250px;
      }

      .list-item {
        color: #062951;
        cursor: pointer;
        display: flex;
        font-size: 13px;
        font-weight: 500;
        justify-content: center;
        margin-bottom: 40px;

        .icon {
          margin-right: 10px;
        }

        .social-media {
          padding: 0 7px;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 760px) {
  .header-top {
    display: none;
  }

  .header {
    top: 0;

    .header-inner {
      margin: 0 25px;
    }

    .right {
      cursor: pointer;

      &.desktop {
        display: none;
      }
    }
  }

  .section-welcome {
    .top {
      display: flex;
      flex-direction: column;
      padding: 25px;
      width: 100%;

      .left {
        .students {
          align-items: flex-start;
          flex-direction: column;

          .link {
            cursor: pointer;
            left: 0;
            width: 100%;
          }
        }

        .title {
          font-size: 30px;
        }
      }

      .right {
        padding-top: 90px;

        .banner-image {

          .yellow-background {
            left: -16px;
            height: 231px;
            top: 20px;
            width: 285px;

            .dot-image {
              left: 233px;
              top: -47px;

              img {
                width: 75px;
              }
            }

            .girl-image {
              bottom: 134px;
              width: 182px;
            }
          }
        }

        .flag-wrap {
          left: 0;
          padding: 0;

          .flag {
            width: 37px;
          }
        }
      }
    }
  }

  .section-video {
    padding: 250px 25px 90px 25px;

    .video {
      width: 100%;

      iframe {
        height: 186px;
        width: 325px;
      }
    }
    .background-shape {
      display: none;
    }
  }

  .section-study-abroad {
    .background-shape {
      display: none;
    }
    .country-banner-wrap {
      width: 325px;

      .country-banner-img {
        height: 186px;
        width: 325px;
      }
    }

    .title {
      font-size: 30px;
    }

    .text {
      width: 316px;
    }
  }

  .section-rates {
    flex-wrap: wrap;
    padding: 25px 25px 0 25px;
    width: 325px;

    .item {
      border-bottom: 1px solid white;
      padding-bottom: 16px;
    }
    .line {
      display: none;
    }
  }

  .section-courses {
    .courses {
      padding: 0 25px;
      width: 100%;

      .img-wrap {
        padding: 5px 5px 10px 5px;
        .course-name {
          font-size: 11px;
          font-weight: 800;
          padding: 6px 0 0 0;
        }
        .image {
          height: 109px;
          width: 148px;
        }
      }
    }

    .title {
      font-size: 30px;
    }

    .text {
      padding: 0 25px;
    }
  }

  .section-student-stories {
    .slick-slider {
      width: 315px !important;
    }

    .title {
      font-size: 30px;
    }
  }

  .section-about-us {
    padding: 25px;

    .about-us-inner {
      flex-direction: column;
      width: 100%;
    }

    .section {
      .description {
        width: 100%;
      }

      .img-map {
        height: 159px;
        width: 324px;
      }
    }
  }

  .footer {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    .right {
      &.mobile {
        display: none ;
      }
    }
  }
  .section-student-stories {
    .slick-slider {
      width: 820px !important;
    }
  }

}