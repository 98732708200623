.page-terms-conditions {
    background-color: #F8F8F8;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 100px;
    z-index: -2;

    .content {
        display: flex;

        .background-shape {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            height: 249px;
            position: relative;
            width: 140px;

            .background-shape-inner {
                background-color: #F8F8F8;
                border-radius: 0 96px 0 0;
                height: 249px;
                width: 140px;
                z-index: 1;
            }
        }

        .content-inner {
            margin: auto;
            padding: 0 10px 100px 150px;

            .section-title {
                color: #062951;
                font-size: 13;
                font-weight: 700;
                margin: 50px 0 10px 0;
                max-width: 1066px;
                margin: auto;
            }

            .text-block {
                color: #062951;
                font-size: 13px;
                margin: 10px 0;
                max-width: 1066px;
                margin: auto;
            }
        }
    }

    .top {
        background-color: white;
        border-radius: 0 0 0 60px;
        padding: 100px 150px 20px 150px;

        .title {
            color: #062951;
            font-size: 40px;
            font-weight: 800;
            margin-bottom: 20px;
            max-width: 1066px;
            margin: auto;

            .text-yellow {
                color: #E0AA32;
            }
        }
    }

}

@media only screen and (min-width: 300px) and (max-width: 760px) {
    .page-terms-conditions {
        .content {
            .background-shape {
                display: none;
            }
            
            .content-inner {
                padding: 25px;
            }
        }

        .top {
            padding: 30px 25px 25px 25px;

            .title {
                font-size: 30px;
              }
        }
    }
}