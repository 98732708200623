.page-documents {
    font-family: 'Poppins', sans-serif;
    background-color: #F8F8F8;
    height: 100vh;

    .content {
        display: flex;
        justify-content: space-between;
    }

    .document-inner {
        height: calc(100vh - 473px);
        padding: 80px 10px 0 150px;
        position: relative;
        top: -385px;
        text-align: left;
        width: calc(100vw - 140px);

        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 1090px;
            margin: auto;

            .dotted-line {
                border-right: 1px dashed;
                margin: 0 auto 0 35px;

                &.line1 {
                    height: 133px;
                }

                &.line2 {
                    height: 50px;
                }
            }

            .section {
                border-radius: 10px;
                height: 70px;
                margin: 0 13px 13px 0;
                padding: 10px 20px;
                width: 310px;

                .download-icon {
                    border-radius: 8px;
                    background-color: #C1AEEB;
                    cursor: pointer;
                    height: 50px;
                    margin-right: 14px;
                    padding: 14px;
                    width: 50px;
                }

                .right {
                    display: block;
                }

                .name {
                    font-size: 13px;
                    font-weight: 500;
                }

                a {
                    &.name {
                        color: #062951;
                    }
                }

                .sub-text {
                    font-size: 10px;
                    font-weight: 500;
                }

                .text {
                    font-size: 10px;
                    font-weight: 400;
                    opacity: 0.5;
                }

                &.green {
                    background-color: #E0FAE4;
                    margin-top: -5px;

                    .sub-text {
                        color: #417148;
                    }
                }

                &.purple {
                    background-color: #ECE5FB;
                    display: flex;
                    margin-top: 16px;
                    padding: 10px;

                    .name {
                        color: #062951;
                    }

                    .sub-text {
                        color: #927BC7;
                    }

                    .text {
                        color: #062951;
                    }

                    &:hover {
                        box-shadow: 0 2px 20px rgb(0 0 0 / 16%);
                    }
                }

                &.yellow {
                    background-color: #F6EDE8;
                    border: 1px solid #E0AA32;
                    margin: 20px 150px -8px 0;
                    padding: 20px 30px;
                    width: 216px;

                    .arrow-icon {
                        margin-left: 40px;
                    }

                    &:hover {
                        box-shadow: 0 2px 20px rgb(0 0 0 / 16%);
                    }
                }

                &.step {
                    font-size: 15px;
                    font-weight: 500;
                    height: 70px;
                    line-height: 16px;
                    margin: 0 50px 0 0;
                    padding: 17px;
                    text-align: center;
                    width: 70px;

                    &.purple {
                        color: #927BC7;

                        &:hover {
                            box-shadow: none;
                        }
                    }

                    &.green {
                        color: #417148;
                        margin-top: -5px;
                    }

                    &.yellow {
                        color: #E0AA32;
                        border: none;

                        &:hover {
                            box-shadow: none;
                        }
                    }
                }
            }

            .left {
                display: flex;
                flex-direction: column;
            }

            .right {
                display: flex;
                flex-wrap: wrap;
                width: 970px;
            }
        }

        .sub-title {
            color: #E0AA32;
            font-size: 13px;
            font-weight: 600;
        }

        .title {
            color: #062951;
            font-size: 40px;
            font-weight: 800;
            margin-bottom: 45px;

            .text-yellow {
                color: #E0AA32;
            }
        }
    }

    .background-shape {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        height: 249px;
        position: relative;
        width: 140px;

        .background-shape-inner {
            background-color: #F8F8F8;
            border-radius: 0 96px 0 0;
            height: 249px;
            width: 140px;
            z-index: 1;
        }
    }

    .page-style {
        background-color: white;
        border-radius: 0 0 0 60px;
        height: 471px;
        z-index: 1;
    }
}

@media only screen and (min-width: 300px) and (max-width: 760px) {
    .page-documents {
        .content {
            .document-inner {
                padding: 25px;
                width: 100%;

                .content {
                    width: 100%;

                    .left {
                        &.desktop {
                            display: none;
                        }
                    }

                    .right {
                        max-height: 1080px;

                        .dotted-line {
                            border-left: 1px dashed;
                            border-right: none;
                            height: 820px;
                            position: relative;
                            width: 100%;
                            z-index: 1;
                        }

                        .section {
                            position: relative;
                            top: -820px;
                            z-index: 2;
                        }

                        .step {
                            margin-bottom: 10px;
                        }
                    }
                }

                .title {
                    font-size: 30px;
                }
            }
        }

        .background-shape {
            left: -40px;
            width: 40px;

            .background-shape-inner {
                width: 40px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .page-documents {
        .content {
            .right {
                .dotted-line {
                    &.mobile {
                        display: none;
                    }
                }

                .section {
                    &.mobile {
                        display: none;
                    }
                }
            }
        }
    }
}